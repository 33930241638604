import React from "react"
import styled from "styled-components"

import Layout from "../layout/Layout"
import MainContent from "../components/Planos/MainContent"

const Planos = () => (
  <Layout title="Planos">
    <Container>
      <MainContent />
      <Section>
        <Bar />
      </Section>
      <Empty />
    </Container>
  </Layout>
)

const Bar = styled.div`
  width: 90vw;
  height: 83px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 0 0 0 76px;
`

const Empty = styled.div`
  height: 30vh;
  width: 100%;
`
const Section = styled.section`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default Planos

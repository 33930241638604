import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { STATIC_PLANS } from "../../services/plans"

import PlanCard from "../PlanCard/PlanCard"

const backgroundPath = require("../../images/contact/background.png")

const MainContent = () => (
  <ImageBackground>
    <LimitContent>
      <TextWrapper>
        <Text>OFERTAS PARA INTERNET DE</Text>
        <TextBold>MEGAVELOCIDADE EM</TextBold>
        <TextBold>JOÃO NEIVA E IBIRAÇU!</TextBold>
      </TextWrapper>

      <Plans>
        {STATIC_PLANS.map(plan => (
          <PlanCard key={`${plan.regulation}-${plan.value}`} plan={plan} />
        ))}
      </Plans>
    </LimitContent>
  </ImageBackground>
)

const ImageBackground = styled.div`
  background-image: url(${backgroundPath});
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  ${media.greaterThan("medium")`
    min-height: 627px;
  `}
`

const Text = styled.p`
  font-size: 1.7rem;
  color: white;
  white-space: normal;
  line-height: 1.2;

  ${media.greaterThan("medium")`
    font-size: 3.06rem;
  `}
`

const TextBold = styled.p`
  font-weight: bold;
  font-size: 1.7rem;
  color: white;
  white-space: normal;
  line-height: 1.2;

  ${media.greaterThan("medium")`
    font-size: 3.06rem;
  `}
`

const LimitContent = styled.div`
  max-width: 1080px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: none;
  height: 100%;

  /* ${media.greaterThan("medium")`
    position: absolute;
    top: 200px;
  `} */
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

const Plans = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  flex-wrap: wrap;
  margin-bottom: 0;
  ${media.greaterThan("medium")`
    margin-bottom: -250px;
  `}
`

export default MainContent
